import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

import { css } from 'emotion';

import {FaTwitter} from 'react-icons/fa';
import {FaFacebook} from 'react-icons/fa';
import {FaGooglePlus} from 'react-icons/fa';
import {FaEnvelope} from 'react-icons/fa';
import {FaPinterest} from 'react-icons/fa';
import {FaLinkedin} from 'react-icons/fa';

import { ShareButtonRectangle, ShareBlockStandard } from 'react-custom-share';


const Sutras = ({sutras, book}) =>(
  <ol>
    {
  sutras.sort(function(sutra1, sutra2){return sutra1.number-sutra2.number}).map(sutra=>{return <li><Link to={"/book" + book + "sutra" + sutra.number + "/"}>{sutra.translation}</Link></li>})
}
  </ol>

)

const IndexPage = ({ data }) => (
  <Layout>
        <ShareBlockStandard {...{
  url: 'https://sutras.yoga',
  button: ShareButtonRectangle,
  buttons: [
    { network: 'Twitter', icon: FaTwitter },
    { network: 'Facebook', icon: FaFacebook },
    { network: 'GooglePlus', icon: FaGooglePlus },
    { network: 'Email', icon: FaEnvelope },
    { network: 'Linkedin', icon: FaLinkedin },
  ],
  text: `The Yoga Sutras of Patanjali are a series sutras (truths) on the study and practice of yoga.`,
  longtext: `Yoga in this meaning is Union, or to bind, the binding of inner mind and outer (universal) mind. In this text Putanjali begins the study of yoga. He organizes these sutras into four books: concentration, practice, spirtual, and isolation.`,
}} />


  { 
      
data.allStrapiBook.edges.map(book =>{
  return ([<h1>Book {book.node.number} - {book.node.translation}</h1>
  ,<Sutras sutras={book.node.sutras} book={book.node.number}/>]
    )
}) }
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    allStrapiBook (sort:{fields:[number], order: ASC}) {
      edges {
        node {
          number
          translation
          sutras{
            number
            translation
          }
        }
      }
    }
  }
  `